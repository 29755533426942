
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poppins:wght@500&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poppins:wght@100;500&family=Roboto&display=swap');
/* .pictureShop {
    font-family: 'Dancing Script',
   cursive, 'Roboto', sans-serif;
} */
/*Handy*/
/* @media only screen and (max-width:700px) { */
    .pictureShop {
        background-color: rgba(0, 0, 0, 0.877);
        width: 80%;
          height: 75%;
          padding: 1vh;
          border-radius: 4%;
          z-index: -100;
          left: 9.75%;
          right: 3%;
          top: 10%;
          position: fixed;
          overflow-y: scroll;
          overflow-x: hidden;
          color: white;
        font-family: 'Dancing Script';
      }
      .pictureShop::-webkit-scrollbar { 
        display: none; 
        }
      
      .fil {
        display:flex;
        justify-content: column;
        width: 3vh;
        height: 2vh;
        cursor: pointer;
        z-index: 100;
        margin: 0% 0% 5% 0%;
      }
      .filterEle {
        margin: 5% -12%;
       
      }
      .filter {
        color: rgb(117, 114, 114);
        font-size: 2.5vh;
        z-index: 100;
      }
      .aw {
        font-size: 3.5vh;
        font-weight: 500;
        position: absolute;
        top: 5%;
       left:5%;
      }
      input:checked {
       cursor: pointer;
       font-size: 10vh;
       background: none;
       border: none;
      }
      #inputCHE {
        cursor: pointer;
        background: none;
        border: none;
      
      }
      .infoFil {
        font-family: 'Roboto';
      }
      .filterOn {
        margin:0% 0%;
        width: 25vh;
        height: 20%;
      
      }
      .euroinfo {
        margin: 0% 35%;
        position: absolute;
        font-family: "Roboto";
      }
      .inPri {
        background: none;
        border: none;
        color: white;
        width: 31%;
        height: 4%;
        margin: 1% 0%;
        position: absolute;
        font-size: 2.5vh;
        font-family: "Roboto";
      }
      .inPri::placeholder {
        font-size: 2.5vh;
      }
      .resHam {
       right: 0%;
       top:0%;
       cursor: pointer;
       position: relative;
        border-radius:5% ;
      }
      .PicHam {
        background-color: rgb(61, 61, 61);
        width: 3vh;
        height:0.5vh;
        z-index: 100;
        margin:8% 1%;
      }
      .PicHam1 {
        background-color: rgb(61, 61, 61);
        width: 2.5vh;
        height:0.5vh;
        z-index: 100;
        margin:8% 1%;
      }
      .PicHam2 {
        background-color: rgb(61, 61, 61);
        width: 2vh;
        height:0.5vh;
        z-index: 100;
        margin:3% 1%;
      }
      .nonePic {
        color: rgb(117, 114, 114);
        font-size: 3vh;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40% 0%;
        position: sticky;
      }
      .imgSh {
       width: 12vh;
       height:18vh;
       border-radius: 6px;
       cursor: pointer;
      }
      .imgSh1 {
        width: 15vh;
        height:20vh;
        border-radius: 6px;
        margin: 0% 5%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
      }
      .imgShA1 {
        max-width: 100vh;
        height: 70vh;
        border-radius: 5%;
        cursor: pointer;
      }
      .pri {
       font-family:'Roboto';
       color: white;
       font-size: 2vh;
       margin-bottom: 15%;
      }
      
      .shopGR {
        flex-direction: column row  ;
        margin-top: 5vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

      }
      .shopGR1 {
        display: flex;
       flex-direction: column  row;
       justify-content: center;
        align-items: center;

      
      } 
      .shopGRR {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .format {
        margin: 0%;
        position: absolute;
        width: 100%;
        font-size: 2.5vh;
        cursor: pointer;
        font-family: 'Roboto' !important;

        
      }
      .sq1 {
        width: 0.75vh;
        height: 0.75vh;
        background-color: white;
        border-radius: 5%;
        margin: 0.25vh 0;
      }
      
      .sq2 {
        width: 0.75vh;
        height: 0.75vh;
        background-color: white;
        border-radius: 5%;
        margin:0.25vh 0;
      }
      
      .sq3 {
        width: 0.75vh;
        height:0.75vh;
        background-color: white;
        border-radius: 5%;
        margin: 0.25vh 0;
      }
      
      .shopGR2 {
        display: flex;
       flex-direction: column  row;
       justify-content: center;
        align-items: center;

      
      }
      .shopGR3 {
        display: flex;
        flex-direction: column  row;
        justify-content: center;
        align-items: center;

      }
      .shopGR4 {
        display: flex;
        flex-direction: column  row;
        justify-content: center;
        align-items: center;

      }
      .shopGR5 {
        display: flex;
        flex-direction:  row;
        justify-content: center;
        align-items: center;

      }
      .shopGR6 {
        display: flex;
        flex-direction:  row;
        justify-content: center;
        align-items: center;

      }
      .shopGR7 {
        display: flex;
        flex-direction:  row;
        justify-content: center;
        align-items: center;

      }
      .shopGR8 {
        display: flex;
        flex-direction:  row;
        justify-content: center;
        align-items: center;

      }
      .shopGR9 {
        display: flex;
        flex-direction:  row;
        justify-content: center;
        align-items: center;

      }
      .shopGR10 {
        display: flex;
        flex-direction:  row;
        justify-content: center;
        align-items: center;

      }
      .shopGR11 {
        display: flex;
        flex-direction:  row;
        justify-content: center;
        align-items: center;

      }
      .elePic {
        height: auto;
        width: auto;
        margin: 0vh 0.75vh;
        grid-area: ele;
      }
      .imgShA1 {
        width:65%;
        height:70.5%;
        border-radius: 5%;
      } 
      .elePic1 {
        width:25vh ;
        height: 25vh;
        margin: 0% 1dvh ;
      }
      .imgShA2 {
        width:90%;
        height:70%;
        border-radius: 5%;
        margin:0% -25%;
      } 
      .imgShA3 {
        width:95%;
        height:70%;
        border-radius: 5%;
        margin:0% -15%;
      } 
      .pri3 {
        
        margin: 0% -15%;
        font-family:'Dancing Script';
        font-family: 'Roboto' !important;
        background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
        color: transparent;
        background-clip: text;
        font-weight: bold;
      }
      .resHam {
        font-family: Arial, Helvetica, sans-serif;
        color: white;
        width: 34%;
        height: 78%;
        padding: 5% 10%  ;
        transition: ease 0.5s;
      
      }
      
      .away {
        font-size: 4vh;
      }
      .imgSHI {
        width:70%;
        height:70.5%;
        max-width: 50vh;
        border-radius: 5%;
        margin: 4% 10% 1% 17%;
      }
      .pri2 {
        font-size: 3vh;
        margin-bottom: 5% ;
        padding:0% 19%;
        font-family: "Roboto", sans-serif;
        color: white;
        font-weight: 800;
        font-weight: 400;
      }
      .btnShop {
        color:rgb(0, 0, 0);
        width: 60%;
        height: 10%;
        margin: 0% 19%;
        font-weight: 1000;
        font-size: 90%;
        border-radius: 5px;
        font-family:'Roboto';
        cursor: pointer;
        background-color: white;
      }
     
      .btnShop1 {
        color:rgb(0, 0, 0);
        width: 100%;
        height: 20%;
        margin: 0%;
        font-weight: 1000;
        font-size: 100%;
        border-radius: 5px;
        font-family:'Roboto';
        cursor: pointer;
        background-color: white;
      }
     
      .formularShop {
        margin: 5% 19%;
      }
      .infoF {
        font-size: 3vh;
        font-weight: 600;
        font-family: "Roboto";
      }
      .inF {
        margin: 2% 0%;
        background: white;
        width: 100%;
        border: none;
        color: black;
        height: 7%;
        font-size: 2.2vh;
        padding: 3%;
        border-radius: 3px;
      }
      .inF::placeholder {
        font-family: "Roboto";
        color: rgb(127, 123, 123);
        
       }
      .ExampleF {
        margin: 1.5% 0% 6% 0% ;
        color: rgb(175, 170, 170);
        font-size: 2.5vh;
        width: 100vw;
        font-family: "Roboto" !important;
      }
      .bPic {
        font-family: 'Roboto' !important;
        width: 96%;
        height: 5vw !important;
        font-size: 3.7vw !important;
        display: flex;
        gap: 3px;
        justify-content: center !important;
        align-items: center !important;
        margin-top: 5%;
        background-color: rgba(66, 65, 65, 0.855);
        padding: 2%;
        border-radius: 5px;
        cursor: pointer;
      }
      .bPicIc {
        font-size: 3.7vw !important;
      }
      .bPic1 {
        font-family: 'Roboto' !important;
        width: 92%;
        height: auto !important;
        font-size: 3.7vw !important;
        display: flex;
        gap: 3px;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        margin-top: 5%;
        background-color: rgba(66, 65, 65, 0.855);
        padding: 4% !important;
        border-radius: 5px;
        cursor: pointer;
        
      }
      .imgBPic {
        height: 85vw !important;
        width: 79vw !important;
        border-radius: 5px;
        display: flex;
        align-items: top !important;
      }
      .ImgBPicPos {
        display: flex !important;
        align-items: top !important;
        justify-content: center;
        width: 100vw !important;
      }
      .PosIF {
        width: 80vw !important;
        font-size: 3vw !important;
        text-align: left;
        margin-left: -5vw;
      }
      .btnBP {
        color:rgb(0, 0, 0);
        width: 95% !important;
        height: 10%;
        margin-top: 5% ;
        font-weight: 1000;
        padding: 5%;
        font-size: 90%;
        border-radius: 5px;
        font-family:'Roboto';
        cursor: pointer;
        background-color: white;
      }
      
/* } */

/*Tablet*/
@media only screen and (min-width:701px) {
  .pictureShop {
    background-color: rgba(0, 0, 0, 0.877);
    width: 75%;
    height: 85%;
    padding: 1vh;
    border-radius: 4%;
    z-index: -100;
    left: 20%;
    right: 3%;
    top: 10%;
    position: fixed;
    overflow-y: scroll;
    color: white;
    width: 80% !important;
    height: 83% !important;
    left: 17% !important;
    right: 3% !important;
    top: 10% !important;
  }
 
  .fil {
    display: flex;
    justify-content: column;
    width: 3vh;
    height: 2vh;
    cursor: pointer;
  }
  .filter {
    color: rgb(117, 114, 114);
    font-size: 2.5vh;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 100;
  }
  .PicHam {
    background-color: rgb(61, 61, 61);
    width: 3vh;
    height:0.5vh;
    z-index: 100;
    margin:8% 1%;
  }
  .PicHam1 {
    background-color: rgb(61, 61, 61);
    width: 2.5vh;
    height:0.5vh;
    z-index: 100;
    margin:8% 1%;
  }
  .PicHam2 {
    background-color: rgb(61, 61, 61);
    width: 2vh;
    height:0.5vh;
    z-index: 100;
    margin:3% 1%;
  }
  .nonePic {
    color: rgb(117, 114, 114);
    font-size: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40% 0%;
    position: sticky;
  }
  .imgSh {
   width: 30vh;
   height:45vh;
   border-radius: 6px;
   cursor: pointer;
  }
  .imgSh1 {
    width: 50vh;
    height:65vh;
    border-radius: 6px;
    cursor: pointer;
   }
  .imgShA1 {
    max-width: 100vh;
    height: 70vh;
    border-radius: 5%;
    cursor: pointer;
  }
  .pri {
    margin-bottom:10% ;
    font-weight: bold;
    font-size: 3vh;
  }
  .shopGR {
    display: flex;
   flex-direction: row;
   padding: 3vh 0.5vh ;
  }
  .shopGR1 {
    display: flex;
   flex-direction: column  row;
   padding:1vh 0.5vh ;
  
  
  } 
  .shopGR2 {
    display: flex;
   flex-direction: column  row;
   padding: 1vh 0.5vh ;
  
  }
  .shopGR3 {
    display: flex;
    flex-direction: column  row;
    padding: 1vh 0.5vh ;
  
  }
  .shopGR4 {
    display: flex;
    flex-direction: column  row;
    padding: 1vh 0.5vh ;
    
  }
  .shopGR5 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  
  }
  .shopGR6 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  }
  .shopGR7 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  }
  .shopGR8 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  }
  .shopGR9 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
   
  }
  .shopGR10 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  }
  .shopGR11 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  }
  .elePic {
    height: auto;
    width: auto;
    margin: 0vh 2vh;
    grid-area: ele;
  }
  .imgShA1 {
    width:65%;
    height:70.5%;
    border-radius: 5%;
  } 
  .elePic1 {
    height: auto;
    width: auto;
    margin: 0%;
  }
  .imgShA2 {
    width:90%;
    height:70%;
    border-radius: 5%;
    margin:0% -25%;
  } 
  .imgShA3 {
    width:95%;
    height:70%;
    border-radius: 5%;
    margin:0% -15%;
  } 
  .pri3 {
    font-family: 'Poppins', sans-serif;
    margin: 0% -15%;
    font-family: 'Dancing Script';
    background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    color: transparent;
    background-clip: text;
  }
  .resHam {
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    width: 34%;
    height: 78%;
    padding: 5% 10%  ;
    transition: ease 0.5s;
  
  }
  
  .away {
    font-size: 4vh;
  }
  .imgSHI {
    width:70%;
    height:80%;
    max-width: 50vh;
    border-radius: 5%;
    margin: 4% 10% 1% 17%;
  }
  .pri2 {
    font-size: 4vh;
    margin: 0% ;
    padding:0% 19%;
    
  }
  .btnShop {
    height: 8%;
    
  }
  .euroinfo {
    margin: 0% 15%;
    position: absolute;
    font-family: "Roboto";
  }
  .inPri {
    background: none;
    border: none;
    color: white;
    width: 12%;
    height: 4%;
    margin:  0%;
    position: absolute;
    font-size: 2.5vh;
  }
  .inPri::placeholder {
    font-size: 2.5vh;
  }
  .infoFil {
    font-family: 'Roboto';
  }
  .filterOn {
    margin:0% 6%;
    width: 25vh;
    height: 20%;
  
  }
  .btnShop1 {
    height: 5vh;
  }
  /**Anzeige von gekauften Bildern Tablet-Version**/
  .bPic {
    font-size: 2.3vw !important;
    height: 6% !important;
  }
  .bPicIc {
    font-size: 2.3vw !important;
  }
  
  .imgBPic {
    height: 50vw !important;
    width: 40vw !important;
  }

  .PosIF {
    width: 80vw !important;
    font-size: 1.5vw !important;
    margin-left: 0vw !important;
  }
  .btnBP {
    width: 100% !important;
    height: 5vw !important;
    padding: 0;
  }
  .bPic1 {
    display: flex;
    flex-direction: row;
  }
}
/*Computer*/
@media only screen and (min-width:1200px) {
   

.pictureShop {
     background-color: rgba(0, 0, 0, 0.877);
      padding: 1vh;
      border-radius: 10px;
      z-index: -100;
      position: fixed;
      overflow-y: scroll;
      color: white;
      width: 80% !important;
      height: 83% !important;
      left: 15% !important;
      right: 3% !important;
      top: 10% !important;
  }
 
  .fil {
    display: flex;
    justify-content: column;
    width: 3vh;
    height: 2vh;
    cursor: pointer;
  }
  .filter {
    color: rgb(117, 114, 114);
    font-size: 2.5vh;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 100;
  }
  .PicHam {
    background-color: rgb(61, 61, 61);
    width: 3vh;
    height:0.5vh;
    z-index: 100;
    margin:8% 1%;
  }
  .PicHam1 {
    background-color: rgb(61, 61, 61);
    width: 2.5vh;
    height:0.5vh;
    z-index: 100;
    margin:8% 1%;
  }
  .PicHam2 {
    background-color: rgb(61, 61, 61);
    width: 2vh;
    height:0.5vh;
    z-index: 100;
    margin:3% 1%;
  }
  .nonePic {
    color: rgb(117, 114, 114);
    font-size: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40% 0%;
    position: sticky;
  }
  .imgSh {
   width: 45vh;
   height:65vh;
   border-radius: 6px;
   cursor: pointer;
  }
  .imgSh1 {
    width: 50vh;
    height:65vh;
    border-radius: 6px;
    cursor: pointer;
   }
  .imgShA1 {
    max-width: 100vh;
    height: 70vh;
    border-radius: 5%;
    cursor: pointer;
  }
  .pri {
    font-family: 'Roboto';
    color: white;
    margin-bottom:10% ;
    font-size: 3vh;
    font-weight: 400 !important;

  }
  .shopGR {
    display: flex;
   flex-direction: row;
   padding: 3vh 0.5vh ;
  }
  .shopGR1 {
    display: flex;
   flex-direction: column  row;
   padding:1vh 0.5vh ;
  
  
  } 
  .shopGR2 {
    display: flex;
   flex-direction: column  row;
   padding: 1vh 0.5vh ;
  
  }
  .shopGR3 {
    display: flex;
    flex-direction: column  row;
    padding: 1vh 0.5vh ;
  
  }
  .shopGR4 {
    display: flex;
    flex-direction: column  row;
    padding: 1vh 0.5vh ;
    
  }
  .shopGR5 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  
  }
  .shopGR6 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  }
  .shopGR7 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  }
  .shopGR8 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  }
  .shopGR9 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
   
  }
  .shopGR10 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  }
  .shopGR11 {
    display: flex;
    flex-direction:  row;
   padding: 1vh 0.5vh ;
  
  }
  .elePic {
    height: auto;
    width: auto;
    margin: 0vh 2vh;
    grid-area: ele;
  }
  .imgShA1 {
    width:65%;
    height:70.5%;
    border-radius: 5%;
  } 
  .elePic1 {
    height: auto;
    width: auto;
    margin: 0%;
  }
  .imgShA2 {
    width:90%;
    height:70%;
    border-radius: 5%;
    margin:0% -25%;
  } 
  .imgShA3 {
    width:95%;
    height:70%;
    border-radius: 5%;
    margin:0% -15%;
  } 
  .pri3 {
    font-family: 'Poppins', sans-serif;
    font-family: 'Roboto' !important;
    margin: 0% -15%;
    font-family: 'Dancing Script';
    background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    color: transparent;
    background-clip: text;
  }
  .resHam {
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    width: 34%;
    height: 78%;
    padding: 5% 10%  ;
    transition: ease 0.5s;
  
  }
  
  .away {
    font-size: 4vh;
  }
  .imgSHI {
    width:70%;
    height:70.5%;
    max-width: 50vh;
    border-radius: 5%;
    margin: 4% 10% 1% 17%;
  }
  .pri2 {
    font-size: 4vh;
    margin: 0% ;
    padding:0% 19%;
    font-family: 'Roboto';
    color: white;
    font-weight: 400 !important;
  }
  .btnShop {
    font-size: 120%;
  }
  
  .euroinfo {
    margin: 0% 11%;
    position: absolute;
  }
  .inPri {
    background: none;
    border: none;
    color: white;
    width: 10%;
    height: 4%;
    margin:  0%;
    position: absolute;
    font-size: 2.5vh;
    font-family: "Dancing Script";
    font-family: 'Roboto' !important;

  }
  .inPri::placeholder {
    font-size: 2.5vh;
  }
  .infoFil {
    font-family: 'Dancing Script';
    font-family: 'Roboto' !important;

  }
  .filterOn {
    margin:0% 7%;
    width: 25vh;
    height: 20%;
  
  }
  .btnShop1 {
    height: 5vh;
  }
  .bPic {
    font-size: 2.3vw !important;
    height: 6% !important;
  }
  .bPicIc {
    font-size: 2.3vw !important;
  }
  
  .imgBPic {
    height: 50vw !important;
    width: 40vw !important;
  }

  .PosIF {
    width: 80vw !important;
    font-size: 1.5vw !important;
    margin-left: 0vw !important;
  }
  .btnBP {
    width: 100% !important;
    height: 5vw !important;
    padding: 0;
  }
  .bPic1 {
    display: flex;
    flex-direction: row;
  }
  
}