@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poppins:wght@500&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poppins:wght@100;500&family=Roboto&display=swap');
 
/**Handy**/
.aboutUs::-webkit-scrollbar {
  display: none;
}
    .aboutUs {
        background-color: rgba(0, 0, 0, 0.877);
        width: 80%;
          height: 75%;
          padding: 2vh;
          border-radius: 4%;
          z-index: -100;
          left: 7%;
          right: 3%;
          top: 10%;
          position: fixed;
          overflow-y: scroll;
      }
      .headAb {
        font-weight: bold;
        background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
        color: transparent;
        background-clip: text;
        font-size: 300%;
        text-align: center;
        font-family:'Dancing Script' ;

      }
      .imp {
       color: white;
        font-family:'Dancing Script' ;
        font-size: 2.4vh;
      }
      summary {
        cursor: pointer;
        font-weight: bold;
        font-size: 2.8vh;
      
      }
      .theAb {
        text-decoration: underline white ;
      }
      .igimg2 {
        width: 4%;
        height: 4%;
        cursor: pointer;
      }
      .ig {
        color: transparent;
        background-image: linear-gradient(to right, rgb(233, 18, 223), rgb(184, 140, 29));
        -webkit-background-clip: text;
        cursor: pointer;
      }
      .ttimg2 {
        width: 5.5%;
        height: 5.5%;
        cursor: pointer;
        margin: -1% 0% ;
      }
      .tt {
        color: transparent;
        background-image: linear-gradient(to right,  rgb(29, 101, 184), rgb(246, 5, 230));
        -webkit-background-clip: text;
        cursor: pointer;
      
      }


/**Tablet**/

@media only screen and (min-width:801px) {
  .aboutUs {
      background-color: rgba(0, 0, 0, 0.877);
      padding: 2vh;
      border-radius: 4%;
      z-index: -100;
      position: fixed;
      overflow-y: scroll;
      width: 80%;
      height: 83%;
      left: 17%;
      right: 3%;
      top: 10%;
  }
  .headAb {
    font-weight: bold;
    background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    color: transparent;
    background-clip: text;
    font-size: 300%;
    text-align: center;
  }
  .imp {
    color: white;
    font-size: 2.5vh;
  }
  summary {
    cursor: pointer;
    font-weight: bold;
    font-size: 3vh;
  
  }
  .theAb {
    text-decoration: underline white;
  }
  .igimg2 {
    width: 1%;
    height: 1%;
    cursor: pointer;
  }
  .ig {
    color: transparent;
    background-image: linear-gradient(to right, rgb(233, 18, 223), rgb(184, 140, 29));
    -webkit-background-clip: text;
    cursor: pointer;
  }
  .ttimg2 {
    width: 1.75%;
    height: 1.75%;
    cursor: pointer;
    margin: -0.5% 0%;
  }
  .tt {
    color: transparent;
    background-image: linear-gradient(to right,  rgb(29, 101, 184), rgb(246, 5, 230));
    -webkit-background-clip: text;
    cursor: pointer;
  
  }
}
/*Computer*/
@media only screen and (min-width:1200px) {
    .aboutUs {
         background-color: rgba(0, 0, 0, 0.877);
          padding: 2vh;
          border-radius: 10px;
          z-index: -100;
          position: fixed;
          overflow-y: scroll;
          width: 80%;
          height: 83%;
          left: 15%;
          right: 3%;
          top: 10%;
      }
      .headAb {
        font-weight: bold;
        background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
        color: transparent;
        background-clip: text;
        font-size: 350%;
        text-align: center;
        margin-bottom: 2%;
        font-family: 'Dancing Script';
      }
      .imp {
        color: white;
        font-size: 2.5vh;
      }
      summary {
        cursor: pointer;
        font-weight: bold;
        font-size: 3vh;
      
      }
      .theAb {
        text-decoration: underline white;
      }
      .igimg2 {
        width: 1.5%;
        height: 1.5%;
        cursor: pointer;
      }
      .ig {
        color: transparent;
        background-image: linear-gradient(to right, rgb(233, 18, 223), rgb(184, 140, 29));
        -webkit-background-clip: text;
        cursor: pointer;
      }
      .ttimg2 {
        width: 2%;
        height: 2%;
        cursor: pointer;
        margin:-0.25% 0%;
      }
      .tt {
        color: transparent;
        background-image: linear-gradient(to right,  rgb(29, 101, 184), rgb(246, 5, 230));
        -webkit-background-clip: text;
        cursor: pointer;
      
      }
}