@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poppins:wght@500&family=Roboto&display=swap');

/**Handy**/
/* @media only screen and (max-width:700px) { */
    .login {
        background-color: rgba(0, 0, 0, 0.744);
        width: 80%;
        height: 73%;
        padding: 3vh 1vh;
         border-radius: 4%;
         z-index: -1000;
         left: 7%;
         right: 3%;
         top: 10%;
         position: fixed;
         font-family: "Roboto";
         overflow-y: scroll;
         overflow-x: hidden;
     }
     .login::-webkit-scrollbar {
      display: none;
     }
     
     .head {
       font-weight: bold;
       background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
       color: transparent;
       background-clip: text;
       font-size: 300%;
       text-align: center;
       font-family: "Dancing Script";
     }
     .WEL {
      font-weight: bold;
      background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
      color: transparent;
      background-clip: text;
      font-size: 200%;
      text-align: center;
      width: auto;
      font-family: "Dancing Script";
     }
     #us {
       margin:10% 0%;
       background: white;
       width: 80%;
       margin: 3% 5%;
       border: none;
       color: black;
       height: 7%;
       font-size: 2.2vh;
       padding: 3%;
       border-radius: 3px;

     }
     #pa {
       background: transparent ;
       border: none;
       width: 75%;
       height: 7%;
       font-size: 2.2vh;
       margin: 3% 5%;
       padding: 3%;
       color: black;
       background-color: white;
       border-radius: 3px;
     
     }
     #us::placeholder {
      font-family: "Roboto";
      color: black;
     }
     #pa::placeholder {
      font-family: "Roboto";
      color: black;

     }
     .icEye {
      font-size: 2.7vh;
     }
     #us1 {
      margin:10% 0%;
      background: white;
      width: 80%;
      margin: 3% 5%;
      border: none;
      color: black;
      height: 7%;
      font-size: 2.2vh;
      padding: 3%;
      border-radius: 3px;
     
     
     }
     #us1::placeholder {
      font-family: "Roboto";
      color: black;
     
     }
     #pa1::placeholder {
      font-family: "Roboto";
      color: white;
     
     }
     
     #pa1 {
      background: transparent ;
      border: none;
      width: 70%;
      height: 7%;
      font-size: 2.2vh;
      margin: 3% 5%;
      padding: 3%;
      color: black;
      background-color: white;
      border-radius: 3px;
     
       
     }
     .labelFor {
      margin: 1.5% 0%  0%  5% ;
      color: white;
     }
     .logB {
      color: black;
      font-family: 'Roboto';
      background-color: white;
      text-align: center;
      margin: -1% 5%;
      font-size: 2.3vh;
      height: 5vh;
      width: 90%;
      border-radius: 5px;
      cursor: pointer;
      font-weight: bold;
     }

     .importantI {
       font-size: 2.5vh;
       margin: 0% 5%;
       color: white;

     }
     .sg  {
     color: rgb(63, 139, 233);
     cursor: pointer;
     text-decoration:underline ;
     
     
     }
     
     p {
       background: none;
       border: 0;
       box-sizing: border-box;
       box-shadow:inset 0 0 0 1px #f96767;
       font-weight: bolder;
       margin:-7% 4%;
      width: auto;
      padding: 3%;
      height: 7%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5%;
      background-color: black;
      background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
       color: transparent;
       background-clip: text;
      font-family: "Dancing Script";
     
     
     }
     .AniB {
       overflow: hidden;
       position: absolute;
       
     }
     .AniB::before, .AniB::after {
       content: '';
       box-sizing: border-box;
       position: absolute;
       border: 3px solid transparent;
       width: 0;
       height: 0;
     }
     .AniB::before {
       top: 0;
       left: 0;
       border-top-color: #60daaa;
       border-right-color: #60daaa;
       animation: border 2s infinite;
     }
     .AniB::after {
       bottom: 0;
       right: 0;
       animation: border 2s 1s infinite, borderColor 2s 1s infinite;
     }
     
     @keyframes border {
       0% {
         width: 0;
         height: 0;
       }
       25% {
         width: 100%;
         height: 0;
       }
       50% {
         width: 100%;
         height: 100%;
       }
       100% {
         width: 100%;
         height: 100%;
       }
     }
     @keyframes borderColor {
       0% {
         border-bottom-color: #60daaa;
         border-left-color: #60daaa;
       }
       50% {
         border-bottom-color: #60daaa;
         border-left-color: #60daaa;
       }
       51% {
         border-bottom-color: transparent;
         border-left-color: transparent;
       }
       100% {
         border-bottom-color: transparent;
         border-left-color: transparent;
       }
     }
     .signUP {
       color: white;
     }
     #email {
       background: transparent ;
       border-bottom: 1% rgb(255, 255, 255) ;
       border-top: none;
       border-right: none;
       border-left: none;
       width: auto;
       height: 5%;
       font-size:155%;
       background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
       color: transparent;
       background-clip: text;
       margin:2% 0%;
       font-family: 'Dancing Script';
     
     }
     #email::placeholder {
       font-family: "Roboto";
       color: white;
     }
     .REG {
       display: flex;
       flex-direction: column;
       margin: 0% 5%;
     }
     .headR {
       font-weight: bold;
       background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
       color: transparent;
       background-clip: text;
       font-size: 300%;
       text-align: center;
       font-family: 'Dancing Script'; 
       margin: -4.5% 0% 3% 0% ;
     }
    .plus {
      font-family: Arial, Helvetica, sans-serif;
      color: white;
      font-size: 4.5vh;
      position: fixed;
      background-color: rgb(43, 42, 42);
      border-radius: 50%;
      width: 4.5vh;
      height: 4.5vh;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 100;
      cursor: pointer;
      inset: 7vh 0vh;
    }
    .addThings {
      font-family: Arial, Helvetica, sans-serif;
      color: white;
      z-index: 1000;
      background-color: rgb(43, 42, 42);
      border-radius: 5px;
      cursor: pointer;
      position: fixed;
      top: 9%;
      left: 3%;
      padding: 1vh;
      transition: ease-in 0.2s;
    }
    .eleT:hover {
      background-color: rgb(69, 69, 69);
    }
    .eleT {
      font-family: 'Dancing Script' ;
    }
    .inPOST {
      background: none;
      background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
      color: transparent;
      background-clip: text;
      font-family: 'Dancing Script';
      font-size: 2.5vh;
      font-weight: 700;
      align-items: center;
      cursor: pointer;
      margin-top: 3vh;
    }
    .imgPOST {
      text-align: center;
      max-width: 300px;
      max-height: 300px;
      margin-top: 5vh ;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5%;

    }
    .headPO {
      font-weight: bold;
       background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
       color: transparent;
       background-clip: text;
       font-size: 300%;
       display: flex;
       justify-content: center;
       align-items: center;
    }
    .imgSPO {
      margin-top: 1vh ;
      max-width: 300px;
      max-height: 300px;
      border: none;
      border-radius: 5%;
    }
   
    .videoPOST {
      text-align: center;
      max-width: 400px;
      max-height: 300px;
      margin-top: 5vh ;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5%;
    }
    .videoSPO {
      margin-top: 1vh ;
      max-width: 400px;
      max-height: 300px;
      border: none;
      border-radius: 5%;
    }
    .inPOST1 {
      background-color: black;
      font-size: 2.5vh;
      width: 70%;
      border-bottom: 1% rgb(255, 255, 255);
      border-right: none;
      border-left: none ;
      border-top: none;
      height: 5%;
    }
    .messagePOST {
      color: white;
      height: auto;
    }
    .signInG {
      font-size: 130%;
      margin: 0% 4%;
      background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
      color: transparent;
      background-clip: text;
      font-family: "Dancing Script";
    }
   
     
/* } */


/**Tablet**/

@media only screen and (min-width:801px) {
  .login {
     background-color: rgba(0, 0, 0, 0.744);
     padding: 3vh 1vh;
     border-radius: 4%;
     z-index: -100;
     position: fixed;
     font-family: 'Dancing Script';
     width: 80%;
     height: 83%;
     left: 17%;
     right: 3%;
     top: 10%;

 }
 
 .head {
   font-weight: bold;
   background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
   color: transparent;
   background-clip: text;
   font-size: 300%;
   text-align: center;
 }
 #us {
  background: white;
  width: 80%;
  margin: 1% 5%;
  border: none;
  color: black;
  height: 5%;
  font-size: 2.2vh;
  padding: 2%;
  border-radius: 5px;
  font-family: 'Roboto';
 
 }
 #pa {
  background: white;
  width: 80%;
  margin: 1% 5%;
  border: none;
  color: black;
  height: 5%;
  font-size: 2.2vh;
  padding: 2%;
  border-radius: 5px;
  font-family: 'Roboto';
 
 }
 #us::placeholder {
   color: black;
   font-family: "Roboto";
 
 }
 #pa::placeholder {
  color: black;
  font-family: "Roboto";
 
 }
 #us1 {
  background: white;
  width: 80%;
  margin: 1% 5%;
  border: none;
  color: black;
  height: 5%;
  font-size: 2.2vh;
  padding: 2%;
  border-radius: 5px;
  font-family: 'Roboto';
 
 }
 #us1::placeholder {
   color: black;
   font-family: "Roboto";
 
 }
 #pa1::placeholder {
  color: black;
  font-family: "Roboto";
 }
 
 #pa1 {
  background: white;
  width: 80%;
  margin: 1% 5%;
  border: none;
  color: black;
  height: 5%;
  font-size: 2.2vh;
  padding: 2%;
  border-radius: 5px;
  font-family: 'Roboto';
 }
 .importantI {
   font-size: 3vh;
   color: white;
   margin: 0% 5%;
   font-family: 'Roboto';
    
 }
 .sg  {
  cursor: pointer;
  text-decoration: underline rgb(63, 139, 233) ;
  font-family: 'Roboto';
  color: rgb(63, 139, 233);

 }
 
 
 .child {
   font-family: 'Dancing Script';
 }
 p {
   background: none;
   border: 0;
   box-sizing: border-box;
   box-shadow:inset 0 0 0 1px #f96767;
   font-weight: bolder;
   margin: 0% 4%;
  width: auto;
  padding: 2%;
  height: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5%;
  background-color: black;
  font-family: 'Dancing Script';
    background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    color: transparent;
    background-clip: text;
 }
 
 
 .AniB {
   overflow: hidden;
   position: absolute;
   
 }
 .AniB::before, .AniB::after {
   content: '';
   box-sizing: border-box;
   position: absolute;
   border: 3px solid transparent;
   width: 0;
   height: 0;
 }
 .AniB::before {
   top: 0;
   left: 0;
   border-top-color: #60daaa;
   border-right-color: #60daaa;
   animation: border 2s infinite;
 }
 .AniB::after {
   bottom: 0;
   right: 0;
   animation: border 2s 1s infinite, borderColor 2s 1s infinite;
 }
 
 @keyframes border {
   0% {
     width: 0;
     height: 0;
   }
   25% {
     width: 100%;
     height: 0;
   }
   50% {
     width: 100%;
     height: 100%;
   }
   100% {
     width: 100%;
     height: 100%;
   }
 }
 @keyframes borderColor {
   0% {
     border-bottom-color: #60daaa;
     border-left-color: #60daaa;
   }
   50% {
     border-bottom-color: #60daaa;
     border-left-color: #60daaa;
   }
   51% {
     border-bottom-color: transparent;
     border-left-color: transparent;
   }
   100% {
     border-bottom-color: transparent;
     border-left-color: transparent;
   }
 }
 .signUP {
   font-family: 'Dancing Script';
   background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
   color: transparent;
   background-clip: text;
 }
 #email {
   background: transparent ;
   border-bottom: 1% rgb(255, 255, 255) ;
   border-top: none;
   border-right: none;
   border-left: none;
   width: auto;
   height: 5%;
   font-size:155%;
  margin:2% 0%;
  font-family: 'Dancing Script';
  background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
  color: transparent;
  background-clip: text;
  
 }
 #email::placeholder {
   font-family: 'Dancing Script';
   background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
   color: transparent;
   background-clip: text;
 }
 .REG {
   display: flex;
   flex-direction: column;
   margin: 0% 5%;
   font-family: 'Dancing Script';
   background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
   color: transparent;
   background-clip: text;
 }
 .headR {
   font-weight: bold;
   background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
   color: transparent;
   background-clip: text;
   font-size: 300%;
   text-align: center;
   
 }
 .plus {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-size: 4.5vh;
  position: fixed;
  background-color: rgb(43, 42, 42);
  border-radius: 50%;
  width: 4.5vh;
  height: 4.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  cursor: pointer;
  inset: 7vh 15vw;
}
.addThings {
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  z-index: 1000;
  background-color: rgb(43, 42, 42);
  border-radius: 5px;
  cursor: pointer;
  position: fixed;
  top: 9%;
  left: 17vw;
  padding: 1vh;
  transition: ease-in 0.2s;
}
.eleT:hover {
  background-color: rgb(69, 69, 69);
}
.eleT {
  font-family: 'Dancing Script' ;
}
.inPOST {
  background: none;
  background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
  color: transparent;
  background-clip: text;
  font-family: 'Dancing Script';
  font-size: 2.5vh;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
  margin-top: 3vh;
}
.imgPOST {
  text-align: center;
  max-width: 300px;
  max-height: 300px;
  margin-top: 5vh ;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5%;

}
.headPO {
  font-weight: bold;
   background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
   color: transparent;
   background-clip: text;
   font-size: 300%;
   display: flex;
   justify-content: center;
   align-items: center;
}
.imgSPO {
  margin-top: 1vh ;
  max-width: 300px;
  max-height: 300px;
  border: none;
  border-radius: 5%;
}

.videoPOST {
  text-align: center;
  max-width: 400px;
  max-height: 300px;
  margin-top: 5vh ;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5%;
}
.videoSPO {
  margin-top: 1vh ;
  max-width: 400px;
  max-height: 300px;
  border: none;
  border-radius: 5%;
}
.inPOST1 {
  background-color: black;
  font-size: 2.5vh;
  width: 70%;
  border-bottom: 1% rgb(255, 255, 255);
  border-right: none;
  border-left: none ;
  border-top: none;
  height: 5%;
}
.messagePOST {
  color: white;
  height: auto;
}
.signInG {
  font-size: 130%;
  margin: 0% 4%;
  background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
  color: transparent;
  background-clip: text;
  font-family: "Dancing Script"; 
 
}

}
/*Computer*/
@media only screen and (min-width:1200px) {
    .login {
        background-color: rgba(0, 0, 0, 0.744);
        padding: 3vh 1vh;
         border-radius: 10px;
         z-index: -100;
         width: 80%;
         height: 83%;
         left: 15%;
         right: 3%;
         top: 10%;
         position: fixed;
         font-family: 'Roboto';
     }
     
     .head {
       font-weight: bold;
       background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
       color: transparent;
       background-clip: text;
       font-size: 300%;
       text-align: center;
     }
     #us {
      background: white;
      width: 80%;
      margin: 1% 5%;
      border: none;
      color: black;
      height: 5%;
      font-size: 2.2vh;
      padding: 1.2%;
      border-radius: 3px;
      font-family: 'Roboto';

     
     }
     #pa {
      background: white;
      width: 80%;
      margin: 1% 5%;
      border: none;
      color: black;
      height: 5%;
      font-size: 2.2vh;
      padding: 1.2%;
      border-radius: 3px;
      font-family: 'Roboto';
     
     }
     #us::placeholder {
       color: black;
       font-family: 'Roboto';
     
     }
     #pa::placeholder {
      color: black;
      font-family: 'Roboto';
     
     }
     #us1 {
      background: white;
      width: 80%;
      margin: 1% 5%;
      border: none;
      color: black;
      height: 5%;
      font-size: 2.2vh;
      padding: 1.2%;
      border-radius: 3px;
      font-family: 'Roboto';
     
     }
     #us1::placeholder {
       color: black;
       font-family: 'Roboto';
     
     }
     #pa1::placeholder {
       color: black;
       font-family: 'Roboto';
     }
     
     #pa1 {
      background: white;
      width: 80%;
      margin: 1% 5%;
      border: none;
      color: black;
      height: 5%;
      font-size: 2.2vh;
      padding: 1.2%;
      border-radius: 3px;
      font-family: 'Roboto';
     }
     .importantI {
       margin: 0% 5%;
       color: white;
       font-family: 'Roboto';
       font-size: 3vh;
     }
     .sg  {
       cursor: pointer;
        color: rgb(63, 139, 233);
        text-decoration: underline rgb(63, 139, 233);
        font-family: 'Roboto';
     }
     
     
     .child {
       font-family: 'Dancing Script';
     }
     .headR {
      margin: 0%;
     }
     p {
       background: none;
       border: 0;
       box-sizing: border-box;
       box-shadow:inset 0 0 0 1px #f96767;
       font-weight: bolder;
       margin: 0% 4%;
      width: auto;
      padding: 2%;
      height: 7%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5%;
      background-color: black;
      font-family: 'Dancing Script';
        background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
        color: transparent;
        background-clip: text;
     }
     
     
     .AniB {
       overflow: hidden;
       position: absolute;
       
     }
     .AniB::before, .AniB::after {
       content: '';
       box-sizing: border-box;
       position: absolute;
       border: 3px solid transparent;
       width: 0;
       height: 0;
     }
     .AniB::before {
       top: 0;
       left: 0;
       border-top-color: #60daaa;
       border-right-color: #60daaa;
       animation: border 2s infinite;
     }
     .AniB::after {
       bottom: 0;
       right: 0;
       animation: border 2s 1s infinite, borderColor 2s 1s infinite;
     }
     
     @keyframes border {
       0% {
         width: 0;
         height: 0;
       }
       25% {
         width: 100%;
         height: 0;
       }
       50% {
         width: 100%;
         height: 100%;
       }
       100% {
         width: 100%;
         height: 100%;
       }
     }
     @keyframes borderColor {
       0% {
         border-bottom-color: #60daaa;
         border-left-color: #60daaa;
       }
       50% {
         border-bottom-color: #60daaa;
         border-left-color: #60daaa;
       }
       51% {
         border-bottom-color: transparent;
         border-left-color: transparent;
       }
       100% {
         border-bottom-color: transparent;
         border-left-color: transparent;
       }
     }
     .signUP {
       font-family: 'Dancing Script';
       background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
       color: transparent;
       background-clip: text;
     }
     #email {
       background: transparent ;
       border-bottom: 1% rgb(255, 255, 255) ;
       border-top: none;
       border-right: none;
       border-left: none;
       width: auto;
       height: 5%;
       font-size:155%;
      margin:2% 0%;
      font-family: 'Dancing Script';
      background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
      color: transparent;
      background-clip: text;
      
     }
     #email::placeholder {
       font-family: 'Dancing Script';
       background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
       color: transparent;
       background-clip: text;
     }
     .REG {
       display: flex;
       flex-direction: column;
       margin: 0% 5%;
       font-family: 'Dancing Script';
       background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
       color: transparent;
       background-clip: text;
     }
     .headR {
       font-weight: bold;
       background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
       color: transparent;
       background-clip: text;
       font-size: 300%;
       text-align: center;
       
     }
     .plus {
      font-family: Arial, Helvetica, sans-serif;
      color: white;
      font-size: 4.5vh;
      position: fixed;
      background-color: rgb(43, 42, 42);
      border-radius: 50%;
      width: 4.5vh;
      height: 4.5vh;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 100;
      cursor: pointer;
      inset: 7vh 15vw;
    }
    .addThings {
      font-family: Arial, Helvetica, sans-serif;
      color: white;
      z-index: 1000;
      background-color: rgb(43, 42, 42);
      border-radius: 5px;
      cursor: pointer;
      position: fixed;
      top: 9%;
      left: 16vw;
      padding: 1vh;
      transition: ease-in 0.2s;
    }
    .eleT:hover {
      background-color: rgb(69, 69, 69);
    }
    .eleT {
      font-family: 'Dancing Script' ;
    }
    .inPOST {
      background: none;
      background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
      color: transparent;
      background-clip: text;
      font-family: 'Dancing Script';
      font-size: 2.5vh;
      font-weight: 700;
      align-items: center;
      cursor: pointer;
      margin-top: 3vh;
    }
    .imgPOST {
      text-align: center;
      max-width: 300px;
      max-height: 300px;
      margin-top: 5vh ;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5%;

    }
    .headPO {
      font-weight: bold;
       background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
       color: transparent;
       background-clip: text;
       font-size: 300%;
       display: flex;
       justify-content: center;
       align-items: center;
    }
    .imgSPO {
      margin-top: 1vh ;
      max-width: 300px;
      max-height: 300px;
      border: none;
      border-radius: 5%;
    }
   
    .videoPOST {
      text-align: center;
      max-width: 400px;
      max-height: 300px;
      margin-top: 5vh ;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5%;
    }
    .videoSPO {
      margin-top: 1vh ;
      max-width: 400px;
      max-height: 300px;
      border: none;
      border-radius: 5%;
    }
    .inPOST1 {
      background-color: black;
      font-size: 2.5vh;
      width: 70%;
      border-bottom: 1% rgb(255, 255, 255);
      border-right: none;
      border-left: none ;
      border-top: none;
      height: 5%;
    }
    .messagePOST {
      color: white;
      height: auto;
    }
    .signInG {
      font-size: 130%;
      margin: 0% 4%;
      background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
      color: transparent;
      background-clip: text;
      font-family: "Dancing Script";
    }
}