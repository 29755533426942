@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poppins:wght@500&family=Roboto&display=swap');
.videogalerie {
    font-family: 'Dancing Script',
    cursive, 'Roboto', sans-serif;
}
.imgBtnV {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
/**Handy**/
@media only screen and (max-width:800px) {
      /**Videogalerie**/
      .videogalerie {
        background-color: rgba(0, 0, 0, 0.877);
        width: 80%;
        height: 80%;
        padding: 1vh;
        border-radius: 4%;
        z-index: -100;
        left: 7%;
        right: 3%;
        top: 10%;
        position: fixed;
         
      }
      .video {
        width: 80%;
        height: 80%;
        border-radius: 5%;
        margin: 1% 10%;
      }
       
      .likeNumber1 {
       color: white;
       font-size: 3vh;
       margin: 0% 5%;
      }
      .imgBtnV {
        margin: 3% 5%;
      }
}


/**Tablet**/

@media only screen and (min-width:801px) {
  .videogalerie {
    background-color: rgba(0, 0, 0, 0.877);
      padding: 1vh;
      border-radius: 10px;
      z-index: -100;
      position: fixed;
      width: 80%;
      height: 83%;
      left: 17%;
      right: 3%;
      top: 10%;
     
  }
  .video {
    width: 60%;
    height: 80%;
    border-radius: 5%;
    margin: 5% 20%;
    z-index: -1;
  }
   
  .likeNumber1 {
   color: white;
   font-size: 3vh;
   margin: 0% 5%;
  }
  .imgBtnV {
    margin: -10% -3%;
  }
  .btn1 {
    z-index: 1000;
  }
  
}
/*Computer*/
@media only screen and (min-width:1200px) {

.videogalerie {
      background-color: rgba(0, 0, 0, 0.877);
      padding: 1vh;
      border-radius: 10px;
      width: 80%;
      height: 83%;
      left: 15%;
      right: 3%;
      top: 10%;
  }
  .video {
    width: 60%;
    height: 70%;
    border-radius: 5%;
    margin: 5% 20%;
    z-index: -1;
  }
   
  .likeNumber1 {
   color: white;
   font-size: 3vh;
   margin: 0% 5%;
  }
  .imgBtnV {
    margin: -5%;
  }
  .btn1 {
    z-index: 1000;
  }
  
  
  
}