@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poppins:wght@500&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Poppins:wght@100;500&family=Roboto&display=swap');
main {
  overflow-x: hidden;
}

.biog {
  font-family: "Roboto", sans-serif; 
}
.container {
  background-color: rgba(0, 0, 0, 0.744);
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 4%;
  z-index: -100;
  
}
.Mitte::-webkit-scrollbar {
  display: none;
}
.text {
  font-family: "Roboto", sans-serif; 

}
.cookie-banner {
  background-color: black;
  font-family: "Roboto", sans-serif; 
  width: 100%;
  position: fixed;
  height: 20%;
  z-index: 1000;
  color: white;
  bottom: 0%;
  transition: 0.2s ease-in-out;
}
.posCookies-banner {
  width: 75%;
  margin: 2% 12.5%;
  display: flex;
  flex-direction: column;
}
.headCookies {
  font-size: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 2%;
}
.acceptCookies {
  margin-top: 6%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(7, 174, 7);
  color: black;
  font-weight: bold;
  height: 5vh;
  border-radius: 5px;
}
.opposeCookies {
  margin-top: 6%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(221, 51, 60);
  color: black;
  font-weight: bold;
  height: 5vh;
  border-radius: 5px;
}
@media screen and (max-width:800px)  {
  .container {
    width: 82%;
    height: 72%;
    top: 10%;
    margin: 0% 7%;
  }
  main {
    background-image: url('./michaelBackground.png');
    background-size: cover;
    background-repeat:no-repeat;
    background-attachment: fixed;
    width: 100%;
    height:100%;
   display: flex;
   justify-content: space-between;
   position: fixed;
   font-family: 'Dancing Script',
   cursive, 'Roboto', sans-serif;
  }
  .foto1 {
    position: sticky;
    margin:0% 25%;
  }
  .foto2 {
    position: sticky;
  }
  .igIcon {
     color: white;
     font-size: 4vh;
     position: sticky;
     margin: 10% 0% ;
     height: 80%;
  }
  .ttIcon {
    color: white;
    font-size: 3.75vh;
    position: sticky;
    margin: 1.5vh 10vw 0vh 0vw;
  }
  .title {
   font-weight: bold;
   font-size: 5vw;
   background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8, #a31cd8);
   background-clip: text;
   color: transparent;
   cursor: pointer;
   width: auto;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100vw;
  }
 
  

  .hamburger-menu {
    font-size: 6.5vw;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 17vw;
    height: 100%;
  }
  .contentHamburgerMenu {
    z-index: 1000;
    width: 100vw;
    color: white;
    background-color: black;
    position: fixed;
    top: 6.5vh;
    transition: ease 0.3s;
    height: 0vh;
  }
  .listMenu {
    color: white;
    
  }
    .header {
    background-color: black;
    width: 100%;
    height: 6.5vh;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
  }
  .shPi {
    color: white;
    width:90%;
    height:75%;
    position: sticky;
  }
  .ic {
   font-size: 5vh;
  }
  .ham {
    margin: 0.2vh 0;
    z-index: 999;
  }
  .linie1 {
    background-image: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    width: 6vh;
    height: 1vh;
    position: fixed;
    border-radius: 10%;
    margin:0.1vh 1vh;
  }
  .linie2 {
    background-image: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    width: 4vh;
    height: 1vh;
    position: fixed;
    border-radius: 10%;
    margin:2.1vh 1vh;
  }
  .linie3 {
    background-image: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    width: 3vh;
    height: 1vh;
    position: fixed;
    border-radius: 10%;
    margin:4.1vh 1vh;
  }
  .click {
    width: 10vh;
    height: 8vh;
    position: absolute;
    }
    .content {
    background: black;  
    position: fixed;
    width: 40%;
    height: 40vh;
    margin: 6vh 0vh ; 
    border-radius: 0% 0% 5% 0%;
    cursor: pointer;
    }
  .textCo{
    background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    background-clip: text;
     color: transparent;
  }
  a {
    text-decoration: none;
    background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    background-clip: text;
     color: transparent;
  }
  ul { 
    list-style: none;
    font-size: 3vh;
  }
  .heNL {
    color: white;
    font-family: 'Roboto', sans-serif;
  }
  
  .imgBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .untenH {
    width: 100%;
    height:  9%;
    position: fixed;
    bottom: 0%;
    left: 0%;
    background-color: black;
  }
  .untenNL {
    width: 100%;
    height:  9%;
    position: fixed;
    bottom: 0%;
    left: 0%;
    background-color: black;
  }
  .posMenuH {
    gap: 0% 9%;
    display: flex;
    flex-direction: row;
    align-items: center ;
    justify-content: center;
  }
  .menu1 {
    display: flex;
    flex-direction: column;
  }
  .unten {
    display: none;
  }
  
  .Mitte {
    text-align: center;
    background: transparent;
    width: 80% !important;
    height: 72% !important;
    top: 10% !important;
    margin: 0% 5% ;
    left: 0% ;
    right: 0% ;
    padding: 5%;
    z-index: -100;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.744);
    border-radius: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    
  }
  .infoA {
    background: linear-gradient(to right,#d682f8,rgb(137, 137, 253), rgb(96, 251, 181) );
    background-clip: text;
    color: transparent;
   font-weight: bold;
   margin: 2% 0%;
   text-align: left;
   font-size: 2.7vh;
   padding: 1% 3% ;
   z-index: -100;
  }
  .info1 {
    background: linear-gradient(to right,#d682f8,rgb(137, 137, 253), rgb(96, 251, 181) );
    background-clip: text;
    color: transparent;
    font-weight:bold ;
    font-size: 4vh;
    z-index: -100;
    position: sticky;
    text-align: center;
    margin-top: -3vh;
  }
  .img1 {
    width: 5.5vh;
    height: 5.2vh;
    top: 0.5%;
    margin: 0% 150%; 
    position: sticky;
  }
  .img2 {
    width: 4.2vh;
    height: 4.2vh;
    top: 1%;
   position: sticky;
   margin:0% -60%;

  }
 .icYou {
   color: white;
   position: sticky;
   top: 0.5%;
   font-size: 4.5vh;
   margin:0% ;
 }
  
  button {
   background: transparent;
   border: none;
   z-index: -100;
  }
  
  
  .info {
    background: linear-gradient(to right,#d682f8,rgb(137, 137, 253), rgb(96, 251, 181) );
    background-clip: text;
    color: transparent;
   font-weight: bold;
   margin: 2% 0%;
   text-align: left;
   font-size: 2.7vh;
   padding: 1% 3% ;
   z-index: -100;
  }
  .searchImg {
    width: 7vh;
    height: 7vh;
    position: sticky;
    z-index: -100;
    }
.element {
  background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
  background-clip: text;
  color: transparent;
    
}
.element:hover {
  color: white;
  text-decoration: underline white;
}
.po {
  position: sticky;
}
.po2{
  position: sticky;
}
.po3{
  position: sticky;
}
.po1 {
  position: sticky;
}
.po4 {
  position: sticky;
}
.shPi {
  position: sticky;
}
.biog {
  margin-top: 10vh;
}
.text {
  font-size: 3vw;
  color: white;
  font-size: "Roboto", sans-serif;
}

.infoUN {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  color: white;
  background-color: black;
  width: 100%;
  right: 0%;
  position: absolute;
  cursor: pointer;
}
.textIF:hover {
  color: yellow;
}
/*Image List*/
.imageList {
  width: 80%;
  height: 74%;
  display: flex;
  flex-direction: column;
  border-radius: 4%;
  position: sticky; 
  margin: -2% 10%;
  text-align: center;
}
.imgI {
  cursor: pointer;
  transition: 0.3s ease-in-out;
  width: 50vw;
  height: 48vh;
  border-radius: 4%;
  max-width: 600px;
  max-height: 500px;
  margin: 7%;
}
.picPos {
  width: 100%;
  height: 100%;
}
.imgBtn {
  display: flex;
  flex-direction: row;
  z-index: 1000;
  gap: 0% 5%;
}
.btn1 {
  color: white;
   font-weight: bold;
   font-size: 5vh;
   width: 6vw;
   height: 6vw;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
   position: sticky;
   z-index: 1000;
 }
/* Translation */
.translation {
 background-color: rgba(0, 0, 0, 0.744);
  width: 120%;
  height:4vh;
  position: fixed;
  bottom:7%;
  display: flex;
  flex-direction: row;
  z-index: 100;
}
.ge {
  background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
  background-clip: text;
  color: transparent;
  font-family: 'Dancing Script';
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in;
  
}
.en {
  background-clip: text;
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in-out;
 
  font-family: 'Dancing Script';
 
}
/* Ausnahme Home */
.ge1 {
  font-family: 'Poppins',  sans-serif;
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in;
 
  
}
.en1 {
  font-family: 'Poppins' , sans-serif;
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in-out;
 

}

/**CSS Search**/ 

 .search {
    background-color: rgba(0, 0, 0, 0.744);
    padding: 3vh 2%;
    border-radius: 10px;
    z-index: -100;
    left: 8%;
    right: 8%;
    top: 10%;
    position: fixed;
    width: 80% !important;
    height: 72% !important;
    top: 10% !important;
}
 .name {
  font-size: 6vh;
  color: rgb(254, 254, 254);
  font-weight: bold;
} 
.backIconS {
  color:white;
  transform:rotate(180deg);
  cursor: pointer;
 inset: 7% 0%;
  position:fixed;
}
.input {
  border: none;
  background-color: transparent;
  width: 90%;
  height: 3%;
  font-size: 120%;
 font-family: 'Dancing Script';
 background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;
}
.input::after {
  content: '';
  background-color: gray;
 width: 1000%;
 height: 500%;
}
.input::placeholder {
 font-size: 100%;
 font-family: 'Dancing Script';
 background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;
}
/* th {
  font-size: 4vh;
 background-image: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;

} */
.searchItem {
  font-weight: bold;
  font-size: 3.5vh;
  position: sticky;
 background-image: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;
  margin: 5% 0% ;
  cursor: pointer;
 }
 .icSearch {
  color: rgb(96, 251, 181) ;
  margin: -1% 0%;
  font-size: 4vh;
 }
}



/* Tablet */
@media screen and (min-width: 801px) {
  .container {
    width: 70%;
    height: 82%;
    padding: 3vh 1vh;
    left: 20%;
    right: 3%;
    top: 11%;
  }
  main {
    background-image: url('./michaelBackground.png');
    background-size: cover;
    background-repeat:no-repeat;
    background-attachment: fixed;
    width: 100%;
    height:100%;
   display: flex;
   justify-content: space-between;
   position: fixed;
   font-family: 'Dancing Script',
   cursive, 'Roboto', sans-serif;
  }
  
  .title {
   color: rgb(255, 255, 255);
   font-weight: 500;
   position: sticky;
   font-size: 3.5vh;
   background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8, #a31cd8);
   background-clip: text;
   color: transparent;
   cursor: pointer;
   width: 80%;
   display: flex;
   align-items: center;
   left: 1%;
  }
  .about {
    font-size: 3.5vh;
    width: auto;
    display: flex;
    align-items:center;
    cursor: pointer;
    background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8, #a31cd8);
   background-clip: text;
   color: transparent;
   font-weight: 500;
   position: fixed;
   top: 1%;
   left: 35%;  

  }

  .hamburger-menu {
    display: none;
  }
  .contentHamburgerMenu {
    display: none;
  }
  
  /* .title:hover {
    font-size: 4.2vh;
  } */
    .header {
    background-color: black;
    width: 100%;
    height: 6%;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
  }
  .igIcon {
    color: white;
    font-size: 4vh;
    position: sticky;
    margin: 1vh 2vw 0vh 0vw ;
 }
 .ttIcon {
   color: white;
   font-size: 3.5vh;
   position: sticky;
   margin: 1vh 10vw 0vh 0vw;
 }
  .shPi {
    color: white;
    width:90%;
    height:75%;
    position: sticky;
  }
  .ham {
    margin: 0.2vh 0;
    z-index: 999;
  }
  .linie1 {
    background-image: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    width: 6vh;
    height: 1vh;
    position: fixed;
    border-radius: 10%;
    margin:0.1vh 1vh;
  }
  .linie2 {
    background-image: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    width: 4vh;
    height: 1vh;
    position: fixed;
    border-radius: 10%;
    margin:2.1vh 1vh;
  }
  .linie3 {
    background-image: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    width: 3vh;
    height: 1vh;
    position: fixed;
    border-radius: 10%;
    margin:4.1vh 1vh;
  }
  .click {
    width: 10vh;
    height: 8vh;
    position: absolute;
    }
    .content {
    background: black;  
    position: fixed;
   width: 40%;
   height: 40vh;
   margin: 6vh 0vh ; 
   border-radius: 0% 0% 5% 0%;
   cursor: pointer;
    }
  .textCo{
    background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    background-clip: text;
     color: transparent;
  }
  a {
    text-decoration: none;
    background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    background-clip: text;
     color: transparent;
  }
  ul { 
    list-style: none;
    font-size: 3vh;
  }
  .Mitte {
    text-align: center;
    background: transparent;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.877);
    padding: 3vh 1vh;
    border-radius: 10px;
    z-index: -100;
    width: 80%;
    height: 83%;
    left: 17%;
    right: 3%;
    top: 10%;
    
  }

  .unten {
    width: auto;
    height:  98%;
    width: 15%;
    background: transparent ;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: black;
    left: 0%;
    top: 5%;
  }
  
  .info1 {
    background: linear-gradient(to right,#d682f8, rgb(96, 251, 181) );
    background-clip: text;
    color: transparent;
    font-weight:bold ;
    font-size: 4.5vh;
    z-index: -100;
    margin:-3% 0%;
    text-align: center;
  }
  
  .he {
    position: absolute;
    font-family: 'Dancing Script';
    font-size: 3vh;
    background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
    color: transparent;
    margin-top: 0%;
  
  }
  .bio {
    display: none;
  }
  .ic {
    font-size: 3.5vh;
  }
  .img1 {
    width: 5vh;
    height: 5vh;
    top: 0.5%;
    margin: 0% 100%; 
    position: sticky;
  }
  .img2 {
    width: 5vh;
    height: 5vh;
    top: 0.5%;
   position: sticky;
  }
 
  
  button {
   background: transparent;
   border: none;
   z-index: -100;
  }
  .heNL {
    font-size: 3vh;
    color: white;
    font-family: "Roboto", sans-serif;
  }
  
  .info {
    background: linear-gradient(to right,#d682f8,rgb(137, 137, 253), rgb(96, 251, 181) );
    background-clip: text;
    color: transparent;
   font-weight: bold;
   margin:3% 0%;
   font-size: 2vw;
   padding: 1% 3% ;
   z-index: -100;
   display: flex;
   align-items: center;
   justify-content: center;
  }
  .searchImg {
    width: 7vh;
    height: 7vh;
    position: sticky;
    z-index: -100;
    }
.element {
  background-image: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
  background-clip: text;
  color: transparent;
    
}
.element:hover {
  color: white;
  text-decoration: underline white;
}
.po {
  position: sticky;
 color: rgb(96, 251, 181);
  
}
.po2{
  position: sticky;
}
.po3{
  position: sticky;
}
.po1 {
  position: sticky;
}
.po4 {
  position: sticky;
}
.shPi {
  position: sticky;
  color: white;
}
.ic {
  position: sticky;
  color: rgb(96, 251, 181);
  margin:0% 4% ;
 
}

.infoUN {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  color: white;
  background-color: black;
  width: 100%;
  right: 0%;
  position: absolute;
  cursor: pointer;
}
.textIF:hover {
  color: yellow;
}
/*Image List*/
.imageList {
  width: 70%;
  height: 75%;
  display: flex;
  flex-direction: column;
  border-radius: 4%;
  position: fixed;
  top: 25%;
  left: 20%;
}
.imgI {
  cursor: pointer;
  transition: 0.3s ease-in-out;
  width: 25vw;
  height: 60vh;
  border-radius: 4%;
  max-width: 600px;
  max-height: 500px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 3% 7%;
}
.btn1 {
  color: white;
   font-weight: bold;
   font-size: 4.5vh;
   width: 10%;
   height: 10%;
   cursor: pointer;
   position: sticky;
 }
 .imgBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0%;
  z-index: 1000;
  width: 45%;
}
.biog {
  position: absolute;
  top: 20%;
  left: 50%;
  width: 50%;
}
.text {
  font-size: 2.5vh;
  color: white;
}
.textTablet {
  font-size: 2.2vh;
}
.posMenuH {
  display: none;
}
/* Translation */
.translation {
 background-color:black;
  width: 100%;
  height:4vh;
  position: fixed;
  top:6%;
  display: flex;
  flex-direction: row;
  z-index: -100;
}
.ge {
  font-family: 'Dancing Script';
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in;
  background-clip:text ;
  font-weight: bold;
  
}
.en {
  font-family: 'Dancing Script';
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in-out;
  background-clip:text ;
 font-weight: bold;
 
}
/* Ausnahme Home */
.ge1 {
  font-family: 'Poppins',  sans-serif;
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in;
 
  
}
.en1 {
  font-family: 'Poppins' , sans-serif;
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in-out;
 

}
/* CSS Chat  */

/**CSS Search**/ 

 .search {
  background-color:rgba(0, 0, 0, 0.877);
   padding: 3vh 1vh;
    border-radius: 10px;
    z-index: -100;
    position: fixed;
    width: 80%;
    height: 83%;
    left: 17%;
    right: 3%;
    top: 10%;
    
}
.name {
  font-size: 6vh;
  background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;
  font-weight: bold;
}
.input {
  border: none;
  background-color: transparent;
  background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;
  width: 90%;
  height: 3%;
  font-size: 120%;
 font-family: 'Dancing Script';

}
.input::after {
  content: '';
  background-color: gray;
 width: 1000%;
 height: 500%;
}
.input::placeholder {
 color: white; 
 font-size: 100%;
 font-family: 'Dancing Script';
 background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
 background-clip: text;
 color: transparent;


}
/* th {
  font-size: 4vh;
 font-family: 'Dancing Script';
 background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;
} */

 .searchItem {
  font-weight: bold;
  font-size: 3.5vh;
  position: sticky;
 background-image: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;
  margin: 2% 0% ;
  cursor: pointer;
 }
 .searchItemT {
   margin: 3% 0%;
 }
 .icSearch {
   font-size: 4vh;
   color: rgb(96, 251, 181);
   margin: -0.5% 0%;
 }
 .backIconS {
  color:white;
  transform:rotate(180deg);
  cursor: pointer;
 inset: 11% 17vw;
  position:fixed;
}
/**Cookies**/

.headCookies {
  font-size: 3vw
}
.acceptCookies {
  margin-top: 6%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(7, 174, 7);
  color: black;
  font-weight: bold;
  height: 5vh;
  border-radius: 5px;
}
.opposeCookies {
  margin-top: 6%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(221, 51, 60);
  color: black;
  font-weight: bold;
  height: 5vh;
  border-radius: 5px;
}
}







/**PC**/
@media screen and (min-width:1500px) {
  .container {
    width: 80%;
    height: 83%;
    left: 15%;
    right: 3%;
    top: 10%;
    padding: 3vh 1vh;
     border-radius: 10px;
     z-index: -100;
  }
  main {
    background-image: url('./michaelBackground.png');
    background-size: cover;
    background-repeat:no-repeat;
    background-attachment: fixed;
    width: 100%;
    height:100%;
   display: flex;
   justify-content: space-between;
   position: fixed;
   font-family: 'Dancing Script',
   cursive, 'Roboto', sans-serif;
  }
  
  .title {
   color: rgb(255, 255, 255);
   font-weight: bold;
   position: sticky;
   font-size: 3.5vh;
   background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8, #a31cd8);
   background-clip: text;
   color: transparent;
   cursor: pointer;
   width: 80%;
   display: flex;
   align-items: center;
   left: 1%;
  }
  .about {
    font-size: 3.5vh;
    width: auto;
    display: flex;
    align-items:center;
    cursor: pointer;
    background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8, #a31cd8);
   background-clip: text;
   color: transparent;
   font-weight: bold;
   position: fixed;
   top: 1%;
   left: 30%;  

  }
  .contentHamburgerMenu {
    display: none;
  }
  .hamburger-menu {
    display: none;
  }
  /* .title:hover {
    font-size: 4.2vh;
  } */
    .header {
    background-color: black;
    width: 100%;
    height: 7%;
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
  }
  .igIcon {
    color: white;
    font-size: 4vh;
    position: sticky;
    margin: 1vh 2vw 0vh 0vw ;
 }
 .ttIcon {
   color: white;
   font-size: 3.5vh;
   position: sticky;
   margin: 1vh 10vw 0vh 0vw;
 }
  .shPi {
    color: white;
    width:90%;
    height:75%;
    position: sticky;
  }
  .ham {
    margin: 0.2vh 0;
    z-index: 999;
  }
  .linie1 {
    background-image: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    width: 6vh;
    height: 1vh;
    position: fixed;
    border-radius: 10%;
    margin:0.1vh 1vh;
  }
  .linie2 {
    background-image: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    width: 4vh;
    height: 1vh;
    position: fixed;
    border-radius: 10%;
    margin:2.1vh 1vh;
  }
  .linie3 {
    background-image: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    width: 3vh;
    height: 1vh;
    position: fixed;
    border-radius: 10%;
    margin:4.1vh 1vh;
  }
  .click {
    width: 10vh;
    height: 8vh;
    position: absolute;
    }
    .content {
    background: black;  
    position: fixed;
   width: 40%;
   height: 40vh;
   margin: 6vh 0vh ; 
   border-radius: 0% 0% 5% 0%;
   cursor: pointer;
    }
  .textCo{
    background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    background-clip: text;
     color: transparent;
  }
  a {
    text-decoration: none;
    background: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
    background-clip: text;
     color: transparent;
  }
  ul { 
    list-style: none;
    font-size: 3vh;
  }

  .unten {
    width: auto;
    height:  98%;
    width: 14%;
    background: transparent ;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: black;
    left: 0%;
    
  }
  .Mitte {
    text-align: center;
    background: transparent;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.877);
    padding: 3vh 1vh;
     border-radius: 10px;
     z-index: -100;
     width: 80%;
     height: 83%;
     left: 15%;
     right: 3%;
     top: 10%;
    
  }
  
 
  .info1 {
    background: linear-gradient(to right,#d682f8, rgb(96, 251, 181) );
    background-clip: text;
    color: transparent;
    font-weight:bold ;
    font-size: 4.5vh;
    z-index: -100;
    margin:-2% 0%;
  }
  .he {
    position: fixed;
    font-family: 'Dancing Script';
    font-size: 3.5vh;
    background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
    color: transparent;
    margin-top: -0.25%;
  
  }
  .heNL {
    font-size: 1.7vw;
    color: white;
    font-family: "Roboto", sans-serif;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    gap: 0% 2%;
  }
 
  .bio {
    display: none;
  }
  .ic {
    font-size: 3.8vh;
    margin-top: 0.75% ;
  }
  .img1 {
    width: 5vh;
    height: 5vh;
    top: 0.5%;
    margin: 0% 100%; 
    position: sticky;
  }
  .img2 {
    width: 5vh;
    height: 5vh;
    top: 0.5%;
   position: sticky;
  }
 
  
  button {
   background: transparent;
   border: none;
   z-index: -100;
  }
  
  
  .info {
    background: linear-gradient(to right,#d682f8,rgb(137, 137, 253), rgb(96, 251, 181) );
    background-clip: text;
    color: transparent;
   font-weight: bold;
   margin:3% 0%;
   text-align: left;
   font-size: 3vh;
   padding: 1% 3% ;
   z-index: -100;
   display: flex;
   align-items: left;
   justify-content: left;
   width: 60%;
  }
  .searchImg {
    width: 7vh;
    height: 7vh;
    position: sticky;
    z-index: -100;
    }
.element {
  background-image: linear-gradient(to right, rgb(96, 251, 181), #a31cd8);
  background-clip: text;
  color: transparent;
    
}
.element:hover {
  color: white;
  text-decoration: underline white;
}
.po {
  position: sticky;
 color: rgb(96, 251, 181);
  
}
.po2{
  position: sticky;
}
.po3{
  position: sticky;
}
.po1 {
  position: sticky;
}
.po4 {
  position: sticky;
}
.shPi {
  position: sticky;
  color: white;
}
.ic {
  position: sticky;
  color: rgb(96, 251, 181);
  margin:0% 4% ;
 
}

.infoUN {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  color: white;
  background-color: black;
  width: 100%;
  right: 0%;
  position: absolute;
  cursor: pointer;
}
.textIF:hover {
  color: yellow;
}
/*Image List*/
.imageList {
  width: 70%;
  height: 75%;
  display: flex;
  flex-direction: column;
  border-radius: 4%;
  position: fixed;
  top: 25%;
  left: 20%;
}
.imgI {
  cursor: pointer;
  transition: 0.3s ease-in-out;
  width: 25vw;
  height: 60vh;
  border-radius: 4%;
  max-width: 600px;
  max-height: 500px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 3% 7%;
}
.btn1 {
  color: white;
   font-weight: bold;
   font-size: 4.5vh;
   width: 10%;
   height: 10%;
   cursor: pointer;
   position: sticky;
 }
 .imgBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -2%;
  z-index: 1000;
  width: 45%;
}
.biog {
  position: absolute;
  top: 17%;
  left: 50%;
  width: 50%;
}
.text {
  font-size: 3vh;
  color: white;
}
.textTablet {
  font-size: 1.3vw;
}
/* Translation */
.translation {
 background-color:black;
  width: 100%;
  height:4vh;
  position: fixed;
  top:6%;
  display: flex;
  flex-direction: row;
  z-index: -100;
}
.ge {
  font-family: 'Dancing Script';
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in;
  background-clip:text ;
  font-weight: bold;
  
}
.en {
  font-family: 'Dancing Script';
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in-out;
  background-clip:text ;
 font-weight: bold;
 
}
/* Ausnahme Home */
.ge1 {
  font-family: 'Poppins',  sans-serif;
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in;
 
  
}
.en1 {
  font-family: 'Poppins' , sans-serif;
  margin: 0 10%;
  cursor: pointer;
  padding: 0%;
  transition: 0.5s ease-in-out;
 

}
/* CSS Chat  */

/**CSS Search**/ 

 .search {

  background-color:rgba(0, 0, 0, 0.877);
   padding: 3vh 1vh;
    border-radius: 10px;
    z-index: -100;
    position: fixed;
    width: 80%;
    height: 83%;
    left: 15%;
    right: 3%;
    top: 10%;
    
    
}
.name {
  font-size: 6vh;
  background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;
  font-weight: bold;
}
.input {
  border: none;
  background-color: transparent;
  background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;
  width: 90%;
  height: 3%;
  font-size: 120%;
 font-family: 'Dancing Script';

}
.input::after {
  content: '';
  background-color: gray;
 width: 1000%;
 height: 500%;
}
.input::placeholder {
 color: white; 
 font-size: 100%;
 font-family: 'Dancing Script';
 background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
 background-clip: text;
 color: transparent;


}
/* th {
  font-size: 4vh;
 font-family: 'Dancing Script';
 background: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;
} */

 .searchItem {
  font-weight: bold;
  font-size: 3.5vh;
  position: sticky;
 background-image: linear-gradient(to right, rgb(96, 251, 181),#a31cd8);
   background-clip: text;
   color: transparent;
  margin: 2% 0% ;
  cursor: pointer;
 }
 .searchItemT {
   margin: 3% 0%;
 }
 .icSearch {
   font-size: 4vh;
   color: rgb(96, 251, 181);
   margin: -0.5% 0%;
 }
 .backIconS {
  color:white;
  transform:rotate(180deg);
  cursor: pointer;
 inset: 11% 17vw;
  position:fixed;
}
.posMenuH {
  display: none;
}

/**About Us **/

}