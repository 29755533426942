
/* .bildgalerie {
    font-family: 'Dancing Script',
    cursive, 'Roboto', sans-serif;
} */
/**Handy**/
/* @media only screen and (max-width:700px) { */
    .bildgalerie {
          background-color: rgba(0, 0, 0, 0.877);
          width: 80%;
          height: 75%;
          padding: 1vh;
          border-radius: 10px;
          z-index: -100;
          left: 10%;
          width: 80% !important;
          height: 72% !important;
          top: 10% !important;
          position: fixed;
          overflow-y: scroll;
        font-size: 'Roboto' !important;
        overflow-x: hidden;

          
      }
      .bildgalerie::-webkit-scrollbar {
        display: none;
      }
      .grid {
       display: grid;
       top: 10%;
       padding: 5%;
      
      }
      .imgG {
         width: 80%;
         height: 95%;
         padding:0% 10% ;
         border-radius: 12%;
         z-index: -1;
       
      }
      .icons1 {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: sticky;
      }
      .like1 {
       position: sticky;
      }
      .comment1 {
        position: sticky;
      }
      .likeNumber {
        color: white;
        font-size: 4vh;
        font-size: 'Roboto' !important;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
         
      }
    
      
      .commentOpen {
        width: 82%;
        height: 55%;
        background-color: black;
        z-index: 100;
        margin: -84% -54%;
        position: fixed;
        overflow-y: scroll;
       border-radius: 5%;
       display: flex;
       flex-direction: column;
       justify-content: flex-end;
      }
      .input1 {
       background-color: black;
       color: white;
       width: 50%;
       height: 4%;
       font-size: 150%;
       position: fixed;
       margin: 0% 6%;
       border: rgb(74, 74, 74) 0.2vh solid;
       border-radius: 5%;
      }
      .input1::placeholder {
        font: 150%;
      }
      .send1 {
        color: white;
        margin: 1% 18%;
        position: fixed;
      }
      
      .newCo {
        padding:87% 0%;
        margin: 3%;
        color: white;
      }
     
/*
}


/**Tablet**/

@media only screen and (min-width:801px) {
  .bildgalerie {
      background-color: rgba(0, 0, 0, 0.877);
      padding: 1vh;
      border-radius: 4%;
      z-index: -100;
      position: fixed;
      overflow-y: scroll;
      font-size: 'Roboto', sans-serif !important;
      width: 80% !important;
      height: 85% !important;
      left: 17% !important;
      right: 3% !important;
      top: 10% !important;

   
  }
  .grid {
   display: grid;
   top: 10%;
   padding: 5%;
  
  }
  .imgG {
     width: 60%;
     height: 90%;
     padding:0% 1% ;
     border-radius: 5%;
     z-index: -1;
     margin: 0% 20%;
   
  }
  .icons1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: sticky;
    margin:  0%;
  }
  .like1 {
   position: sticky;
  }
  .comment1 {
    position: sticky;
  }
  .likeNumber {
    color: white;
    font-size: 5vh;
    font-size: 'Roboto' !important;
    
  }
  
  
  
  
  .commentOpen {
    width: 82%;
    height: 55%;
    background-color: black;
    z-index: 100;
    margin: -84% -54%;
    position: fixed;
    overflow-y: scroll;
   border-radius: 5%;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
  }
  .input1 {
   background-color: black;
   color: white;
   width: 50%;
   height: 4%;
   font-size: 150%;
   position: fixed;
   margin: 0% 6%;
   border: rgb(74, 74, 74) 0.2vh solid;
   border-radius: 5%;
  }
  .input1::placeholder {
    font: 150%;
  }
  .send1 {
    color: white;
    margin: 1% 18%;
    position: fixed;
  }
  
  .newCo {
    padding:87% 0%;
    margin: 3%;
    color: white;
  }
}
/*Computer*/
@media only screen and (min-width:1200px) {
  /**CSS Bildgalerie**/
.bildgalerie {
      background-color: rgba(0, 0, 0, 0.877);
      padding: 1vh;
      border-radius: 10px;
      z-index: -100;
      position: fixed;
      overflow-y: scroll;
      width: 80% !important;
      height: 83% !important;
      left: 15% !important;
      right: 3% !important;
      top: 10% !important;
      font-size: 'Roboto' !important;

  }
  .grid {
   display: grid;
   top: 10%;
   padding: 5%;
  
  }
  .imgG {
     width: 40%;
     height: 90%;
     padding:0% 1% ;
     border-radius: 14%;
     z-index: -1;
     margin: 0% 30%;
   
  }
  .icons1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: sticky;
    margin:  0%;
  }
  .like1 {
   position: sticky;
  }
  .comment1 {
    position: sticky;
  }
  .likeNumber {
    color: white;
    font-size: 5vh;
  
  }
  
  
  
  
  .commentOpen {
    width: 82%;
    height: 55%;
    background-color: black;
    z-index: 100;
    margin: -84% -54%;
    position: fixed;
    overflow-y: scroll;
   border-radius: 5%;
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
  }
  .input1 {
   background-color: black;
   color: white;
   width: 50%;
   height: 4%;
   font-size: 150%;
   position: fixed;
   margin: 0% 6%;
   border: rgb(74, 74, 74) 0.2vh solid;
   border-radius: 5%;
  }
  .input1::placeholder {
    font: 150%;
  }
  .send1 {
    color: white;
    margin: 1% 18%;
    position: fixed;
  }
  
  .newCo {
    padding:87% 0%;
    margin: 3%;
    color: white;
  }
}



